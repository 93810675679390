import { useSelector, useDispatch } from "react-redux";
import { setUser, userLoggedOut } from "store/auth/userSlice";
import {
  onSignInSuccess,
  onSignOutSuccess,
} from "../../store/auth/sessionSlice";
import { REDIRECT_URL_KEY } from "../../constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import axiosInstance from "../../apiServices/axiosInstance";
import appConfig from "../../configs/app.config";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { token, expired } = useSelector((state) => state?.auth?.session);

  const signIn = async ({ user_email, password }) => {
    // console.log("userData  setUserData in Auth module: ", userData);
    try {
      const formData = {
        user_email: user_email,
        password: password,
      };
      const response = await axiosInstance.post("user/user-sign-in", formData);
      // console.log("🚀 ~ signIn ~ response:", response)
      if (response.status) {
        const { token, result } = response;
        dispatch(onSignInSuccess(token));
        const userData = result[0];
        // console.log("🚀 ~ signIn  in useAuth page~ userData:", userData)
        userData &&
          dispatch(
            setUser({
              user_id: userData?.user_id ? userData.user_id : 0,
              user_email: userData.user_email
                ? userData.user_email
                : "Guest@gmail.com",
              password: userData.password ? userData.password : "",
              authority: userData.user_role
                ? [userData.user_role.toLowerCase()]
                : ["user"],
              //multiple entry
              profile_id: userData.profile_ids?.length ? userData.profile_ids[0] : "",
              profile_data: userData.profile_data?.length
                ? userData.profile_data
                : [{ value: "user", label: "user" }],
              selected_profile: userData?.selected_profile
                ? userData.selected_profile
                : userData.profile_data[0],// still it is not usable
              child_profile_id: !userData?.child_profile_id?.includes(null) && userData?.child_profile_id?.length > 0 ? true : false,
            })
          );

        if (userData.profile_data?.length) {
          const redirectUrl = query.get(REDIRECT_URL_KEY);
          // console.log("🚀 ~ signIn ~ redirectUrl:", redirectUrl)
          navigate(
            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
          );
          return response;
        } else {
          navigate(appConfig.tourPath);
          return response;
        }
        // return response;
      } else {
        return response;
      }
    } catch (errors) {
      // console.log("signIn error:", errors.message);
      return {
        status: false,
        message: errors?.response?.data?.message || errors?.toString(),
      };
    }
  };

  const setUserData = async (user_id, redirectUrl) => {
    // console.log("setUserData called", user_id)
    try {
      const response = await axiosInstance.get(`user/get-user/${user_id}`, {});
      // console.log("response setUserData: ", response);
      if (response.status) {
        const userData = response?.data[0];
        // console.log("🚀 ~ setUserData ~ userData:", userData)
        // const settingsData = data.settingsData;
        // console.log("userData  setUserData in Auth module: ", userData);
        userData &&
          dispatch(
            setUser({
              user_id: userData?.user_id ? userData.user_id : 0,
              user_email: userData?.user_email
                ? userData.user_email
                : "Guest@gmail.com",
              password: userData?.password ? userData.password : "",
              authority: userData?.user_role
                ? [userData.user_role.toLowerCase()]
                : ["user"],
              //multiple entry
              profile_id: userData.profile_ids?.length ? userData.profile_ids[0] : "",
              profile_data: userData?.profile_data?.length
                ? userData.profile_data
                : [{ value: "user", label: "user" }],
              selected_profile: userData?.selected_profile
                ? userData.selected_profile
                : userData.profile_data[0],// still it is not usable
              child_profile_id: !userData?.child_profile_id?.includes(null) && userData?.child_profile_id?.length > 0 ? true : false
            })
          );

        if (userData?.profile_ids?.length) {
          // console.log("Enter into if block")
          navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
          return response;
        } else {
          // console.log("Enter into else block")
          navigate(redirectUrl ? redirectUrl : appConfig.tourPath);
          return response;
        }
        // return response;
      } else {
        return response;
      }
    } catch (errors) {
      // console.log("signIn error:", errors.message);
      return {
        status: false,
        message: errors?.response?.data?.message || errors?.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(userLoggedOut());
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };

  return {
    authenticated: token && signIn && checkAuthenticated(),
    signIn,
    setUserData,
    signOut,
  };

}

export default useAuth;
